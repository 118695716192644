import React, { useEffect } from 'react'
import './tabs.css';
import Tree from '../Media/funtree.png';
import bt from   '../Media/boringTree.jpg';
import lt from   '../Media/lonelyTree.jpg';
import nt from   '../Media/nuclearTree.jpg';

import funeralQuotes from '../Items/funeralQuotes';


function FuneralTab() {
  useEffect(() => {
    const treePic = document.getElementById('funtree');
    treePic.style.borderRadius = treePic.width/2 +'px';
  }, [])

  const refreshFunQuote=()=> {
    let newQuote = funeralQuotes[Math.floor((Math.random() * funeralQuotes.length))]; 
    let quote = document.getElementById('fun-quote');
    quote.innerHTML = `<h2>${newQuote.text}</h2><p>${newQuote.attribution}</p>`;
  };

  useEffect(()=>{
    refreshFunQuote();
  }, []);


  return (
    <div className='tab-page' id='fun-page'>
      <div id='fun-quote' className='card' onClick={refreshFunQuote}></div>
      <sub id='fun-quote-prompt'>Sometimes it is hard to find the right words for the occasion so please click/tap on the quote above to cycle through my selected quotes for inspiration</sub>
      <div>
        <h1>Funeral Celebrant</h1>
        <img id='funtree' src={nt}></img>
        <h2>What is a Funeral Celebrant?</h2>
        <p>A Funeral Celebrant is a qualified person that officiates at funeral services by planning and overseeing funeral proceedings with the family.</p>
        <p>Funeral Celebrants conduct non-religious, and spiritual funeral services.</p>
        <p>As a Celebrant I will aim for the funeral service to be a ‘celebration of life’ that honours the person’s memory.</p>
        <p>This approach places greater emphasis on how they lived their life, their personality, and the memories of mourners.</p>
        <p>As your Celebrant, I will facilitate the creation, by you, of a ceremony of substance and meaning.</p>
      </div>

      <h3>The most important part of being a Celebrant is that I tailor the service around you and what you want.</h3>

      <div>
        <h2>When do you need a Funeral Celebrant?</h2>
        <ul id='fun-wnc'>
          <li>When you need someone to take charge of the main speaking role in a funeral service.</li>
          <li>When you want someone who can be flexible when planning the service.</li>
          <li>When you would like help, guidance and advice arranging the ceremony.</li>
        </ul>
        <ul id='fun-wid'>
          <h3>What I do:</h3>
          <li>Discuss the type of service you wish to have.</li>
          <li>Find out about your loved one and their life.</li>
          <li>Prepare a draft plan and script for the service.</li>
          <li>Confirm with you the details and changes.</li>
          <li>Lead the Service on the day.</li>
          <li>Provide you with a copy of the eulogy afterwards.</li>
        </ul>
      </div>
    </div>
  )
}

export default FuneralTab