import React, {useEffect} from 'react';
import Logo from '../Media/cust-tree-logo.png';
import { Menu, MenuOpen } from '@mui/icons-material';
import './Header2.css';

function Header2({menuAction, menuOpen}) {
  useEffect(()=> {
    const menuIcon      = document.getElementById('menu-icon');
    const menuIconOpen  = document.getElementById('menu-icon-open');
    if (menuOpen) {
      menuIconOpen.style.display = 'flex';
      menuIcon.style.display = 'none';
    } else {
      menuIconOpen.style.display = 'none';
      menuIcon.style.display = 'flex';
    }
  }, [menuOpen])

  return (
    <div id='header2'>
        <div id='banner'>
            <div id='logodiv'>
                <img id='logo' src={Logo} alt='logo' />
            </div>
            <div id='namespace'>
                <h1 id='dm'>DUNCAN MOFFATT</h1>
                <h2 id='fc'>FAMILY CELEBRANT</h2>
            </div>
            <div 
              id='menu' 
              onClick={menuAction}
            >
              <div id='menu-top-border'></div>
              <Menu 
                titleAccess='menu'
                id='menu-icon' 
                sx={{color: 'gold', fontSize: '5rem'}} 
              />
              <MenuOpen 
                titleAccess='menu-open'
                id='menu-icon-open' 
                sx={{color: 'gold', fontSize: '5rem'}} 
              />
              <div>MENU</div>
            </div>
        </div>
    </div>
  )
}

export default Header2