import React, { useEffect, useState } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import MailIcon from '@mui/icons-material/Mail';
import './pointbox.css';

function PointBox({point, exp, contact}) {
  return (
    <div className='privacy-box card'>
        <div id='privacy-point' 
          style={{justifyContent:`${contact && 'space-between'}`}}
        >
          { contact === 'fb'  ? <FacebookIcon   style={{fontSize:'4rem'}}/> : 
            contact === 'ig'  ? <InstagramIcon  style={{fontSize:'4rem'}}/> :
            contact === 'em'  ? <MailIcon       style={{fontSize:'4rem'}}/> : ""
          }
          <span
            style={{color:`${contact ? 'white' : 'goldenrod'}`}}
          >
            {point}
          </span>
        </div>
        <div 
          id='privacy-ans' 
          style={{display:`${contact ? 'none' : 'flex'}`}}
        >{exp}</div>
    </div>
  )
}

export default PointBox