import React from 'react'
import PointBox from '../Items/PointBox'
import ProfPic from '../Media/DMFC.jpg';


function ContactTab() {
  const EM_LINK = 'mailto:duncan.moffatt@hotmail.co.uk';
  const FB_LINK = 'https://www.facebook.com/DMCelebrant';
  const IG_LINK = 'https://www.instagram.com/dmoffattcelebrant';

  return (
    <div
      id='contact-page'
      className='tab-page'
    >
      <img
        id='contact-photo'
        src={ProfPic}
        alt='Business card'
      ></img>
      <div 
        id='sm-links'
        >
        <h3
          style={{textAlign:'center'}}
        >Please feel free to join me on social media</h3>
        <div 
          id='option-btns'
        >
          <a
            className='contact-item'
            href={FB_LINK}
            target='_blank'
            >
            <PointBox
              contact='fb'
              point='FACEBOOK'
              exp=''
              onClick={() => this.openLink('fb')}
              ></PointBox>
          </a>

          <a
            className='contact-item'
            href={IG_LINK}
            target='_blank'
            >
            <PointBox
              contact='ig'
              point='INSTAGRAM'
              exp=''
              onClick={() => this.openLink('ig')}
            ></PointBox>
          </a>

          <a
            className='contact-item'
            href={EM_LINK}
            target='_blank'
            >
            <PointBox
              contact='em'
              point='E-MAIL'
              exp=''
              onClick={() => this.openLink('em')}
            ></PointBox>
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactTab