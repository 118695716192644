import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import React, {useEffect, useState } from 'react'
import Header2 from './components/Header/Header2';
import AboutTab from './components/Tabs/AboutTab';
import WeddingsTab from './components/Tabs/WeddingsTab';
import FuneralTab from './components/Tabs/FuneralTab';
import OtherTab from './components/Tabs/OtherTab';
import PrivacyTab from './components/Tabs/PrivacyTab';
import ContactTab from './components/Tabs/ContactTab';


function App4() {
  const [menuShown, setMenuShown] = useState(true);

  const showHideMenu=()=> {
    setMenuShown(!menuShown);
    let tabList = document.getElementById('tabList')
    tabList.style.visibility = menuShown ? 'visible' : 'hidden';
    // tabList.style.height = menuShown ? 'auto' : '0px';
  }


    const tabnames = ['ABOUT ME', 'WEDDINGS', 'FUNERALS', 'OTHER SERVICES', 'PRIVACY', 'CONTACT'];
    const tabfiles = [<AboutTab />,   <WeddingsTab />, <FuneralTab />, <OtherTab />, <PrivacyTab />, <ContactTab />];

  return (
    <div id='app4'>
      <Header2 
        menuAction={showHideMenu}
        menuOpen={!menuShown}
      />
      <div id='border-ribbon'></div>
      <Tabs>
        <TabList 
          id='tabList' 
        >
          {tabnames.map(i=> <Tab className={'tab4'} onClick={showHideMenu}>{i}</Tab>)}
        </TabList>
        {tabfiles.map(i=> <TabPanel>{i}</TabPanel>)}
      </Tabs>
    </div>
  )
}

export default App4