function funeralQuotes () {
	let quotes = [
    {text: ["What we once enjoyed and deeply loved we can never lose,",
            "for all that we love deeply becomes part of us"], 
      attribution: ["Unknown"]},
		{text: ["The song is ended, but the melody lingers on…"], 
      attribution: ["Irving Berlin"]},
    {text: ["Life is either a daring adventure, or nothing."], 
      attribution: ["Helen Keller"]},
    {text: ["Turn your hurt into healing,",
            "your wounds into wisdom,","and your pain into power."], 
      attribution: ["Robin Sharma"]},
    {text: ["Everyone must leave something behind when he dies, my grandfather said.",
            "A child or a book or a painting or a house or a wall built or a pair of shoes made.",
            "Or a garden planted. Something your hand touched some way so your soul has somewhere to go when you die,",
            "and when people look at that tree or that flower you planted, you're there.",
            "It doesn't matter what you do, he said,",
            "so as long as you change something from the way it was before you touched it",
            "into something that's like you after you take your hands away."], 
      attribution: ["Fahrenheit 451"]},
    {text: ["I fall asleep in the full and certain hope",
            "That my slumber shall not be broken;",
            "And that, though I be all-forgetting,",
            "Yet shall I not be all-forgotten,",
            "But continue that life in the thoughts and deeds",
            "of those I have loved."],
      attribution: ["Samuel Butler"]},
    {text: ["God gave us memory so we may have roses in December."], 
      attribution: ["J.M. Barrie"]},
    {text: ["My sister you are with me always",
            "whether near or far.",
            "For you see,",
            "we're connected",
            "by the heart."], 
      attribution: ["Unknown"]},
    {text: ["I submit to you that if a man hasn't discovered something he will die for, he isn't fit to live."], 
      attribution: ["Martin Luther King Jr"]},
    {text: ["If there ever comes a day where we can't be together,",
            "keep me in your heart. I'll stay there forever."],
      attribution: ["Winnie the Pooh"]},
    {text: ["It's the circle of life,", 
            "and it moves us all,",
            "through despair and hope,",
            "through faith and love,",
            "'till we find our place,",
            "on the path unwinding."], 
      attribution: ["The Lion King"]},
    {text: ["There are only two ways to live your life.",
            "One is as though nothing is a miracle.",
            "The other is as though everything is a miracle."], 
      attribution: ["Albert Einstein"]},
    {text: ["The idea is not to live forever",
            "but to create something that will."], 
      attribution: ["Andy Warhol"]},
    {text: ["People are like stained-glass windows.",
            "They sparkle and shine when the sun is out,",
            "but when the darkness sets in;",
            "their true beauty is revealed",
            "only if there light is from within."], 
      attribution: ["Elizabeth Kubler Ross"]},
    {text: ["Death is more universal than life;",
            "everyone dies,",
            "but not everyone lives."], 
      attribution: ["Alan Sachs"]},
    {text: ["We make a living by what we get;",
            "we make a life by what we give"], 
      attribution: ["Winston Churchill"]},
    {text: ["Some are bound to die young",
            "By dying young a person stays young",
            "in people's memory.",
            "If he burns brightly before he dies,",
            "his brightness shines for all time"],
        attribution: ["Unknown"]},
    {text: ["I am living proof that a shattered heart can still beat."], 
      attribution: ["Maryellen Dennis"]},
    {text: ["There are special people in our lives",
            "that never leave us even",
            "after they are gone."], 
      attribution: ["Unknown"]},
    {text: ["The pain passes,",
            "but the beauty remains."], 
      attribution: ["Pierre Auguste Renoir"]},
    {text: ["In three words I can sum up everything",
            "I've learned about life: it goes on."], 
      attribution: ["Robert Frost"]},
    {text: ["It's hard to turn the page when you know someone won't",
            "be in the next chapter,",
            "but the story must go on."], 
      attribution: ["Unknown"]},
    {text: ["Just living is not enough…",
            "one must have sunshine,",
            "freedom,",
            "and a little flower"], 
      attribution: ["Hans Christian Andersen"]},
    {text: ["It matters not what someone is born,",
            "but what they grow up to be."], 
      attribution: ["J.K. Rowling"]},
    {text: ["The comfort of having a friend may be taken away,",
            "but not that of having had one."], 
      attribution: ["Seneca"]},
    {text: ["Don't cry because it's over,",
            "smile because it happened."], 
      attribution: ["Dr. Seuss"]},
    {text: ["In the end, it's not the years in your life that count.",
            "It's the life in your years."], 
      attribution: ["Abraham Lincoln"]},
    {text: ["What we once enjoyed and deeply loved we can never lose,",
            "for all that we love deeply becomes part of us."], 
      attribution: ["Helen Keller"]},
    {text: ["The best and most beautiful things in the world cannot be seen, not touched.",
            "But are felt in the heart."], 
      attribution: ["Helen Keller"]},
    {text: ["I've learned that people will forget what you said,",
            "people will forget what you did,",
            "but people will never forget how you made them feel."], 
      attribution: ["Maya Angelou"]},
    {text: ["They that love beyond the world cannot be separated by it.",
            "Death cannot kill what never dies."], 
      attribution: ["William Penn"]},
    {text: ["Life is short. Always choose happiness."], 
      attribution: ["Unknown"]},
    {text: ["Goodbyes are only for those who love with their eyes.",
            "Because for those who love with heart and soul there is no such thing as separation."], 
      attribution: ["Rumi"]},
    {text: ["You are gone",
            "but thank you",
            "for all these soft, sweet things",
            "you left behind",
            "in my home,",
            "in my head,",
            "in my heart."], 
      attribution: ["Nikita Gill"]},
    {text: ["Grief is the price we pay for love"], 
      attribution: ["Queen Elizabeth II"]},
    {text: ["Grief is just love with no place to go."], 
      attribution: ["Jamie Andserson"]},
    {text: ["I wish you knew how much of you there is in everything I do.",
            "It can be the smallest thing…trivial…mundane…but you're there.",
            "Under the surface of it somewhere.",
            "I wish you knew how I carry you with me always…",
            "everywhere I go."], 
      attribution: ["Ranata Suzuki"]},
    {text: ["I still don't know how to look at my life without seeing an inescapable absence of you."], 
      attribution: ["Chloë Frayne"]},
    {text: ["I am trying to remember you and let you go at the same time."], 
      attribution: ["Unknown"]},
    {text: ["Sometimes,",
            "only one person is missing,",
            "and the whole world seems depopulated."], 
      attribution: ["Alphonse de Lamartine"]},
    {text: ["There's still a beat…",
            "but the music is gone.",
            "And you're the only song my heart wants to hear."], 
      attribution: ["Ranata Suzuki"]},
    {text: ["Grief is a nasty game of feeling the weakest you have ever felt and becoming the strongest person you will ever be."], 
      attribution: ["Windgate Lane"]},
    {text: ["Hardships often prepare ordinary for an extraordinary destiny."], 
      attribution: ["C. S Lewis"]},
    {text: ["I do not want the peace which passeth understanding,",
            "I want the understanding which bringeth peace."], 
      attribution: ["Helen Keller"]},
    {text: ["The people who pretend that dying is rather like strolling into the next room always leave me unconvinced.",
            "Death, like birth, must be a tremendous event."], 
      attribution: ["J. B. Priestley"]},
    {text: ["It's what you do,",
            "unthinking,",
            "that makes the quick tear start;",
            "the tear may be forgotten…",
            "but the hurt stays in the heart."], 
      attribution: ["Ella Higginson"]},
    {text: ["Her absence is like the sky,",
            "spread over everything."], 
      attribution: ["C.S Lewis"]},
    {text: ["The bitterest tears shed over graves are for words left unsaid and deeds left undone."], 
      attribution: ["Harriet Beecher Stowe"]},
    {text: ["The passing of the years awakens in our hearts the cry for permanence."], 
      attribution: ["F. B. Meyer"]},
    {text: ["My sister will die over and over again for the rest of my life.",
            "Grief is forever.",
            "It doesn't go away;",
            "it becomes a part of you,",
            "step for step,",
            "breath for breath.",
            "I will never stop grieving Bailey because I will never stop loving her.",
            "That's just how it is.",
            "Grief and love are conjoined,",
            "you don't get one without the other.",
            "All I can do is love her,",
            "and love the world,",
            "emulate her by living with daring and spirit and joy."], 
      attribution: ["Jandy Nelson,","The Sky is Everywhere"]},
    {text: ["If I can see pain in your eyes then share with me your tears.",
            "If I can see joy in your eyes then share with me your smile."], 
      attribution: ["Santosh Kalwar"]},
    {text: ["We talk about them,",
            "not because we're stuck or we haven't moved on,",
            "but we talk about them because we are theirs,",
            "and they are ours,", 
            "and no passage of time can change that."], 
      attribution: ["Scribbles & Crumbs"]},
    {text: ["Even hundredfold grief is divisible by love."], 
      attribution: ["Terri Guillemets"]},
    {text: ["The risk of love is loss, and the price of loss is grief.",
            "But the pain of grief is only a shadow when compared with the pain of never risking love."], 
      attribution: ["Hilary Stanton Zunin"]},
    {text: ["There is no grief like the grief that does not speak."], 
      attribution: ["Henry Wordsworth"]},
    {text: ["Those who have suffered understand suffering and therefore extend their hand."], 
      attribution: ["Patti Smith"]},
    {text: ["There is a sacredness in tears.",
            "They are not the mark of weakness, but of power.",
            "They speak more eloquently than ten thousand tongues.",
            "They are the messengers of overwhelming grief,",
            "of deep contrition,",
            "and of unspeakable love."], 
      attribution: ["Washington Irving"]},
    {text: ["Only people who are capable of loving strongly can also suffer great sorrow,",
            "but this same necessity of loving serves to counteract their grief and heals them."], 
      attribution: ["Leo Tolstoy"]},
    {text: ["No one ever told me that grief felt so like fear."], 
      attribution: ["C.S. Lewis", "A Grief Observed"]},
    {text: ["Grief is love's shadow.",
            "The presence of absence.",
            "An unbearable weight of emptiness."], 
      attribution: ["John Mark Green"]},
    {text: ["To spare oneself from grief at all cost can be achieved only at the price of total detachment, which excludes the ability to experience happiness."],
      attribution: ["Erich Fromm"]},
    {text: ["We must embrace pain and burn it as fuel for our journey."], 
      attribution: ["Kenji Miyazawa"]},
    {text: ["Nothing on earth can make up for the loss  of one who has loved you."], 
      attribution: ["Selma Lagerlof"]},
    {text: ["Grief knits two hearts in closer bonds than happiness ever can;",
            "And common sufferings are far stronger links than common joys."], 
      attribution: ["Alphonse de Lamartine"]},
    {text: ["Part of getting over it is knowing that you will never get over it."], 
      attribution: ["Ann Finger"]},
    {text: ["Death ends a life, not a relationship."], 
      attribution: ["Jack Lemmon"]},
      {text: ["Say not in grief he is no more – but live in thankfulness that he was"], 
        attribution: ["A Hebrew Proverb"]},
    {text: ["If we have been pleased with life,",
            "we should not be displeased with death,",
            "since it comes from the hand of the same master."], 
      attribution: ["Michelangelo"]},
    {text: ["They will come back, come back again,",
            "As long as the red Earth rolls.",
            "He never wasted a leaf or a tree.",
            "Do you think He would squander souls?"], 
      attribution: ["Rudyard Kipling"]},
    {text: ["Perhaps they are not stars,", 
            "but rather openings in Heaven where our loved ones shine down to let us know they are happy"], 
      attribution: ["An Eskimo Legend"]},
    {text: ["Death is not the end.",
            "Death can never be the end.",
            "Death is the road.",
            "Life is the traveller.",
            "The Soul is the Guide",
            "Our mind thinks of death.",
            "Our heart thinks of life",
            "Our soul thinks of Immortality."], 
      attribution: ["Sri Chinmoy"]},
    {text: ["Yesterday is a memory,",
            "tomorrow is a mystery and today is a gift,",
            "which is why it is called the present.",
            "What the caterpillar perceives is the end; to the butterfly is just the beginning.",
            "Everything that has a beginning has an ending.",
            "Make your peace with that and all will be well."], 
      attribution: ["A Buddhist Saying"]},
    {text: ["When you are born, you cry, and the world rejoices.",
            "When you die, you rejoice, and the world cries."], 
      attribution: ["A Buddhist Saying"]},
    {text: ["Say not in grief that she is no more but say in thankfulness that she was.",
            "A death is not the extinguishing of a light, but the putting out of the lamp because the dawn has come."], 
      attribution: ["Rabindranath Tagore"]},
    {text: ["Life and death are but phases of the same thing, the reverse and obverse of the same coin.",
            "Death is as necessary for man's growth as life itself."], 
      attribution: ["Mahatma Gandhi"]},
    {text: ["We know you'd be with us today if heaven wasn't so far away."], 
      attribution: ["Unknown"]},
    {text: ["The darker the night, the brighter the stars,",
            "The deeper the grief, the closer is God!"], 
      attribution: ["Fyodor Dostoyevsky", "Crime and Punishment"]},
    {text: ["When he shall die,",
            "Take him and cut him out in little stars,",
            "And he will make the face of heaven so fine",
            "That all the world will be in love with night,",
            "And pay no worship to the garish sun."], 
      attribution: ["William Shakespeare", "Romeo and Juliet"]},
    {text: ["If death could be seen as a beautiful clear lake, refreshing and buoyant,",
            "then when a consciousness moves towards its exit from a body there would be that delightful plunge and it would simply swim away."], 
      attribution: ["Pat Rodegast"]},
    {text: ["The boundaries between life and death are at best shadowy and vague.",
            "Who shall say where one ends and where the other begins?"], 
      attribution: ["Edgar Allen Poe"]},
    {text: ["It is not length of life, but depth of life."], 
      attribution: ["Emerson Ralph Waldo"]},
    {text: ["Perhaps passing through the gates of death is like passing quietly through the gate in a pasture fence.",
            "On the other side, you keep walking, without the need to look back.",
            "No shock, no drama,",
            "just the lifting of a plank or two in a simple wooden gate in a clearing.",
            "Neither pain, nor floods of light, nor great voices,",
            "but just the silent crossing of a meadow."], 
      attribution: ["Mark Helprin"]},
    {text: ["Every one can master a grief but he that has it."], 
      attribution: ["William Shakespeare", "Much Ado About Nothing"]},
    {text: ["Break, break, break,",
            "On thy cold gray stones, O Sea!",
            "And I would that my tongue could utter",
            "The thoughts that arise in me",
            "And the stately ships go on",
            "To their haven under the hill;",
            "But O for the touch of a vanish'd hand,",
            "and the sound of a voice that is still"], 
      attribution: ["Alfred, Lord Tennyson"]},
    {text: ["I believe that imagination is stronger than knowledge,",
            "that myth is more potent that history.",
            "I believe that dreams are more powerful than facts,",
            "that hope always triumphs over experience,",
            "that laughter is the only cure for grief.",
            "And I believe that love is stronger than death"], 
      attribution: ["The Crow"]},
    {text: ["One day can make your life.",
            "One day can ruin your life.",
            "All life is, is four or five days that change everything."], 
      attribution: ["Beverly Donofrio"]},
    {text: ["Dying is a very dull, dreary affair.",
            "And my advice to you is to have nothing whatever to do with it."], 
      attribution: ["Somerset Maughan"]},
    {text: ["Nothing in life is certain except death and taxes"], 
      attribution: ["Benjamin Franklin"]},
    {text: ["Immortality: a fate worse than death."], 
      attribution: ["Edgar Shoaff"]},
    {text: ["The art of dying graciously is nowhere advertised, in spite of the fact that its market potential is great."], 
      attribution: ["Milton Mayer"]},
    {text: ["At my age, I'm often asked if I'm frightened of death and my reply is always, I can't remember being frightened of birth."], 
      attribution: ["Peter Ustinov"]},
    {text: ["Life does not cease to be funny when people die any more than it ceases to be serious when people laugh."], 
      attribution: ["George Bernard Shaw"]},
    {text: ["A friend of mine stopped smoking, drinking, overeating, and chasing women – all at the same time.",
            "It was a lovely funeral."], 
      attribution: ["Unknown"]},
    {text: [`The annoying thing about being an atheist is that you'll never have the satifaction of saying to believers, "I told you so."`], 
      attribution: ["Mark Steel"]},
    {text: ["Life is pleasant.",
            "Death is peaceful.",
            "It's the transition that's troublesome"], 
      attribution: ["Isaac Asimov"]},
    {text: ["Either this man is dead or my watch has stopped"], 
      attribution: ["Groucho Marx"]},
    {text: ["The idea is to die young as late as possible"], 
      attribution: ["Ashley Montagu"]},
    {text: ["Most people can't bear to sit in church for an hour on Sundays.",
            "How are they supposed to live somewhere very similar to it for eternity?"], 
      attribution: ["Mark Twain"]},
    {text: ["According to most studies, people's number one fear is public speaking.",
            "Number two is death. Death is number two! Does that sound right?",
            "This means to the average person, if you go to a funeral, you're better off in the casket than doing the eulogy."], 
      attribution: ["Jerry Seinfeld"]},
    {text: ["The living are just the dead on holiday"], 
      attribution: ["Maurice Maeterlinck"]},
    {text: ["Death: A fate worse than life…"], 
      attribution: ["J. J. Furnas"]},
    {text: ["It's not that I'm afraid to die, I just don't want to be there when it happens."], 
      attribution: ["Woody Allen"]},
    {text: ["I am ready to meet my Maker.",
            "Whether my Maker is prepared for the great ordeal of meeting me is another matter."], 
      attribution: ["Winston Churchill"]},
    {text: ["Always look on the bright side of life…"], 
      attribution: ["Monty Python"]},
  ];

    let format=(txt)=> txt.join('<br />');

    let formattedQuotes = quotes.map(i => i.text=format(i.text));
    let formattedQuoter = quotes.map(i => i.attribution=format(i.attribution));

    let toReturn = [];

    for (let i=0; i<quotes.length; i++) toReturn.push({text:formattedQuotes[i], attribution:formattedQuoter[i]});
    return toReturn;
};



export default funeralQuotes();