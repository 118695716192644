import React from 'react';
import Duncan from '../Media/duncan.jpg';

function AboutTab() {
  return (
    <div className='tab-page'>
      <div id='abt-quote2'>
          <div className='quote'>
            <h2>"The more you praise and celebrate your life, the more there is in life to celebrate"</h2>
            <p>Oprah Winfrey</p>
          </div>
      </div>
      <div id='abt-title-card'>
        <h1>Greetings</h1>
        <p>I am Duncan Moffatt a registered Celebrant located in the East Midlands and able to travel throughout that region.</p>
        <p>I also have a home in Torrevieja, near Alicante, Spain, and would be able to undertake Ceremonies in that Area and would be happy to do so.</p>
        <img id='duncan2' src={Duncan}></img>
        <h3>Why did I decide to Train as a Celebrant? </h3>
        <p>Why did I decide to Train as a Celebrant? I have had extensive experience of public speaking during my time as a Rotarian and as a Mason, I have had extensive training experience in my own college business, I am experienced at delivering speeches and standing with confidence in front of an audience ranging from 4 or 5 people to that of hundreds. I have also been honoured to speak the eulogy at a number of funerals, and to M.C. at weddings and special occasions.</p>
        <p>I have many hobbies and welcome enquiries from motorcyclists and those who love sailing as well as Masonic and Rotarian members.</p>
        <p>Having run my own businesses since 1981, I have now retired from my main employment, and wish to fill my time with a role I enjoy, and can assist in adding gravitas and form to special occasions. I can make happy occasions memorable, and sad occasions unforgettable.</p>
        <p>I am passionate in what I undertake to do, committed to invoking special memories that will remain with you now and always.</p>
        <p>These ceremonies will be what you always imagined and wished for,<br />
        They will be yours.<br />
        <span style={{fontSize:'2rem'}}>Your Day in Your Way.</span></p>
      </div>
      <div>
        <p>For more information about my services please click on the menu icon at the top of this page.</p>
      </div>
    </div>
  )
}

export default AboutTab