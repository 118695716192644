function weddingQuotes () {
	let quotes = [
		{text: ["In all the world, there is no heart for me like yours.","In all the world, there is no love for you like mine."], 
		attribution: ["Maya Angelou"]},
		{text: ["Love is passion, obsession, someone you can't live without."], 
		attribution: ["William Parrish"]},
		{text: ["Do I love you? My god, if your love were a grain of sand, mine would be a universe of beaches."], 
		attribution: ["William Goldman,","The Princess Bride"]},
		{text: ["If I know what love is, it is because of you."], 
		attribution: ["Herman Hesse,","Narcissus and Goldmund"]},
		{text: ["I love you not only for what you are, but for what I am when I am with you."], 
		attribution: ["Elizabeth Barrett Browning"]},
		{text: ["You know you're in love when you don't want to fall asleep because reality is finally better than your dreams."], 
		attribution: ["Dr. Seuss"]},
		{text: ["Love seems the swiftest but it is the slowest of all growths.","No man or woman really knows what perfect love is until they have been married a quarter of a century."], 
		attribution: ["Mark Twain"]},
		{text: ["Love takes off masks that we fear we cannot live without and know we cannot live within."], 
		attribution: ["James Baldwin,","The Fire Next Time"]},
		{text: ["Love is the thing that is always having the last word","even when those words are 'I'm sorry,' or 'Forgive me,' or 'I love you no matter what.'"], 
		attribution:["Cleo Wade"]},
		{text: ["I love you without knowing how, or when, or from where.","I love you directly, without problems or pride:","I love you like this because I don't know any other way to love,","except in this form in which I am not nor are you,","so close that your hand upon my chest is mine,","so close that your eyes close with my dreams."], 
		attribution: ["Pablo Neruda,","One Hundred Love Sonnets: XVII"]},
		{text: ["You are the love that came without warning; you had my heart before I could say no."], 
		attribution: ["Unknown"]},
		{text: ["The best love is the kind that awakens the soul and makes us reach for more.","That plants a fire in our hearts and brings peace to our minds."], 
		attribution: ["Nicholas Sparks,","The Notebook"]},
		{text: ["For it was not into my ear you whispered, but into my heart. It was not my lips you kissed, but my soul."], 
		attribution: ["Judy Garland"]},
		{text: ["How in hell can you handle love without turning your life upside down?","That’s what love does, it changes everything."], 
		attribution: ["Lauren Bacall"]},
		{text: ["I have for the first time found what I can truly love. I have found you."], 
		attribution: ["Charlotte Brontë,","Jane Eyre"]},
		{text: ["Love has nothing to do with what you are expecting to get—only with what you are expecting to give—which is everything."], 
		attribution: ["Katharine Hepburn"]},
		{text: ["Loving you never was an option. It was a necessity."], 
		attribution: ["Truth Devour,","Unrequited"]},
		{text: ["To be fully seen by somebody, then, and be loved anyhow—this is a human offering that can border on miraculous."], 
		attribution: ["Elizabeth Gilbert,","Committed: A Skeptic Makes Peace with Marriage"]},
		{text: ["Love doesn’t make the world go round. Love is what makes the ride worthwhile."], 
		attribution: ["Elizabeth Barrett Browning"]},
		{text: ["Each time you love, love as deeply as if it were forever."], 
		attribution: ["Audre Lorde"]},
		{text: ["It is love that makes the impossible, possible."], 
		attribution: ["Indian Proverb"]},
		{text: ["One word frees us of all the weight and pain of life: That word is love."], 
		attribution: ["Sophocles"]},
		{text: ["Being deeply loved by someone gives you strength. While loving someone deeply gives you courage."], 
		attribution: ["Lao Tzu"]},
		{text: ["I carry your heart with me","(I carry it in my heart).","I am never without it","(anywhere I go you go, my dear; and whatever is done by only me is your doing, my darling)."],
		attribution: ["E. E. Cummings,","I Carry Your Heart With Me"]},
		{text: ["You pierce my soul. I am half agony, half hope...I have loved none but you."], 
		attribution: ["Jane Austen,","Persuasion"]},
		{text: ["If I had a flower for every time I thought of you...I could walk through my garden forever."],
		attribution: ["Alfred,","Lord Tennyson"]},
		{text: ["And I’d choose you;","in a hundred lifetimes,","in a hundred worlds,","in any version of reality,","I’d find you and I’d choose you."],
		attribution: ["Kiersten White,","The Chaos of Stars"]},
		{text: ["For all the things my hands have held, the best by far is you."],
		attribution: ["Andrew McMahon in the Wilderness"]},
		{text: ["They slipped briskly into an intimacy from which they never recovered."],
		attribution: ["F. Scott Fitzgerald,","This Side of Paradise"]},
		{text: ["Keep love in your heart. A life without it is like a sunless garden when the flowers are dead."],
		attribution: ["Oscar Wilde"]},
		{text: ["To burn with desire and keep quiet about it is the greatest punishment we can bring on ourselves."],
		attribution: ["Federico García Lorca,","Blood Wedding and Yerma"]},
		{text: ["The very essence of romance is uncertainty."],
		attribution: ["Oscar Wilde,","The Importance of Being Earnest"]},
		{text: ["I would rather share one lifetime with you than face all the ages of this world alone."],
		attribution: ["J.R.R. Tolkien,","The Lord of the Rings"]},
		{text: ["My bounty is as boundless as the sea, my love as deep;","the more I give to thee, the more I have, for both are infinite."],
		attribution: ["Shakespeare,","Romeo and Juliet"]},
		{text: ["Yours is the light by which my spirit’s born: you are my sun, my moon, and all my stars."],
		attribution: ["E.E. Cummings,","You are My Sun, My Moon, and All My Stars"]},
		{text: ["If you live to be 100, I hope I live to be 100 minus one day, so I never have to live without you."],
		attribution: ["A. A. Milne,","Winnie-the-Pooh"]},
		{text: ["You have made a place in my heart where I thought there was no room for anything else.","You have made flowers grow where I cultivated dust and stones."],
		attribution: ["Robert Jordan,","The Shadow Rising"]},
		{text: ["You make me happier than I ever thought I could be and if you let me, I will spend the rest of my life trying to make you feel the same way."],
		attribution: ["Chandler, proposing to Monica on Friends"]},
		{text: ["He felt now that he was not simply close to her, but that he did not know where he ended and she began."],
		attribution: ["Leo Tolstoy,","Anna Karenina"]},
		{text: ["I was made and meant to look for you and wait for you and become yours forever."],
		attribution: ["Robert Browning,","Pauline: The Fragment of a Confession"]},
		{text: ["What greater thing is there for two human souls, than to feel that they are joined for life","to strengthen each other in all labour, to rest on each other in all sorrow,","to minister to each other in all pain, to be one with each other in silent unspeakable memories at the moment of the last parting?"],
		attribution: ["George Eliot,","Adam Bede"]},
		{text: ["Doubt thou the stars are fire,","Doubt the sun doth move,","Doubt truth to be a liar but never doubt thy love."],
		attribution: ["William Shakespeare,","Hamlet"]},
		{text: ["I love thee to the depth and breadth and height my soul can reach."],
		attribution: ["Elizabeth Barret Browning,","How Do I Love Thee?"]},
		{text: ["Grow old along with me; the best is yet to be."],
		attribution: ["Robert Browning,","Rabbi Ben Ezra"]},
		{text: ["Love is like a friendship caught on fire."],
		attribution: ["Jeremy Taylor"]},
		{text: ["There is no more lovely, friendly, and charming relationship, communion or company than a good marriage."],
		attribution: ["Martin Luther"]},
		{text: ["For the two of us, home isn't a place. It is a person. And we are finally home."],
		attribution: ["Stephanie Perkins,","Anna and the French Kiss"]},
		{text: ["I can conquer the world with one hand, as long as you’re holding the other."],
		attribution: ["Unknown"]},
		{text: ["The best thing to hold onto in life is each other."],
		attribution: ["Audrey Hepburn"]},
		{text: ["When we love, we always strive to become better than we are.","When we strive to become better than we are, everything around us becomes better too."],
		attribution: ["Paulo Coelho,","The Alchemist"]},
		{text: ["You don't marry the person you can live with—you marry the person you can't live without."],
		attribution: ["Unknown"]},
		{text: ["The best use of life is love.","The best expression of love is time.","The best time to love is now."],
		attribution: ["Rick Warren"]},
		{text: ["I swear I couldn’t love you more than I do right now, and yet I know I will tomorrow."],
		attribution: ["Leo Christopher"]},
		{text: ["Thinking of you keeps me awake.","Dreaming of you keeps me asleep.","Being with you keeps me alive."],
		attribution: ["Unknown"]},
		{text: ["You are my heart, my life, my one and only thought."],
		attribution: ["Arthur Conan Doyle,","The White Company"]},
		{text: ["A hundred hearts would be too few to carry all my love for you."],
		attribution: ["Unknown"]},
		{text: ["My love for you is a journey, starting at forever and ending at never."],
		attribution: ["Unknown"]},
		{text: ["There is only one happiness in life: To love and be loved."],
		attribution: ["George Sand"]},
		{text: ["A life without love is like a year without summer."],
		attribution: ["Lithuanian Proverb"]},
		{text: ["Who, being loved, is poor?"],
		attribution: ["Oscar Wilde,","A Woman of No Importance"]},
		{text: ["You're something between a dream and a miracle."],
		attribution: ["Elizabeth Barret Browning"]},
		{text: ["Where there is love there is life."],
		attribution: ["Ghandi"]},
		{text: ["The water shines only by the sun. And it is you who are my sun."],
		attribution: ["Charles de Leusse"]},
		{text: ["I look at you and see the rest of my life in front of my eyes."],
		attribution: ["Unknown"]},
		{text: ["When I look at you, I see everything I've ever wanted."],
		attribution: ["Anonymous"]},
		{text: ["You are every lovely word I could think of."],
		attribution: ["Unknown"]},
		{text: ["Once in a while, right in the middle of an ordinary life, love gives us a fairy tale."],
		attribution: ["Anonymous"]},
		{text: ["If I get married, I want to be very married."],
		attribution: ["Audrey Hepburn"]},
		{text: ["May this marriage be full of laughter, our every day in paradise."],
		attribution: ["Rumi"]},
		{text: ["Romance is the icing, but love is the cake."],
		attribution: ["Unknown"]},
		{text: ["Great love affairs start with champagne..."],
		attribution: ["Honoré de Balzac"]},
		{text: ["Sexiness wears thin after a while and beauty fades, but to be married to a man who makes you laugh every day, ah, now that's a real treat."],
		attribution: ["Joanne Woodward"]},
		{text: ["A successful marriage requires falling in love many times, always with the same person."],
		attribution: ["Mignon McLaughlin"]},
		{text: ["The great marriages are partnerships. It can’t be a great marriage without being a partnership."],
		attribution: ["Helen Mirren"]},
		{text: ["I would not wish any companion in the world but you."],
		attribution: ["Shakespeare,","The Tempest"]},
		{text: ["People are weird. When we find someone with weirdness that is compatible with ours, we team up and call it love."],
		attribution: ["Dr. Seuss"]},
		{text: ["The greatest marriages are built on teamwork. A mutual respect, a healthy dose of admiration, and a never-ending portion of love and grace."],
		attribution: ["Fawn Weaver"]},
		{text: ["The secret of a happy marriage is finding the right person. You know they’re right if you love to be with them all the time."],
		attribution: ["Julia Child"]},
		{text: ["Remember, we all stumble; every one of us.","That is why it’s a comfort to go hand in hand."],
		attribution: ["Emily Kimbrough"]},
		{text: ["Walking with your hands in mine and mine in yours, that’s exactly where I want to be always."],
		attribution: ["Fawn Weaver"]},
		{text: ["Love is not about staring at each other, but staring off in the same direction."],
		attribution: ["Antoine de Saint Exupéry"]},
		{text: ["Being married is like having somebody permanently in your corner.","It feels limitless, not limited."],
		attribution: ["Gloria Steinem"]},
		{text: ["It's amazing how one day someone walks into your life,","and suddenly,","you can't remember how you lived without them."],
		attribution: ["Anurag Prakash Ray"]},
		{text: ["Marriage flourishes when the couple works together as a team: when both husband and wife decide that winning together is more important than keeping score.","Good marriages don't just happen. They are a product of hard work."],
		attribution: ["Michelle Obama"]},
		{text: ["To find someone who will love you for no reason,","and to shower that person with reasons,","that is the ultimate happiness."],
		attribution: ["Robert Brault"]},
		{text: ["Love starts as a feeling,But to continue is a choice;","And I find myself choosing you,","More and more every day."],
		attribution: ["Justin Wetch,","Bending the Universe"]},
		{text: ["Love at the lips was touch","As sweet as I could bear;","And once that seemed too much;","I lived on air."],
		attribution: ["Robert Frost,","To Earthward"]},
		{text: ["Love","Is a ripe plum","Growing on a purple tree.","Taste it once","And the spell of its enchantment","Will never let you be."],
		attribution: ["Langston Hughes,","A Love Song for Lucinda"]},
		{text: ["Love is not love","Which alters when it alteration finds","Or bends with the remover to remove","O no! it is an ever-fixed mark","That looks on tempests and is never shaken;","It is the star to every wand'ring bark","Whose worth is unknown","although his height be taken."],
		attribution: ["William Shakespeare,","Sonnet 116"]},
		{text: ["Our love will bloom always fairer","Fresher,","more gracious","Because it is a true love"],
		attribution: ["Honoré de Balzac"]}
	];

	let format=(txt)=> txt.join('<br />');

	let formattedQuotes = quotes.map(i => i.text=format(i.text));
	let formattedQuoter = quotes.map(i => i.attribution=format(i.attribution));

	let toReturn = [];

	for (let i=0; i<quotes.length; i++) toReturn.push({text:formattedQuotes[i], attribution:formattedQuoter[i]});
	return toReturn;
};



export default weddingQuotes();