import React from 'react';
import PointBox from '../Items/PointBox';
import DM from '../Media/dm.jpg'

const services = [
  {
    service:"Internment Services",
    desc:"After you have collected the ashes, many people are at a loss as to what to do next. I can work with you to decide what you feel would be a fitting final resting place, and help with a short service whilst we give your loved one their final send off."
  },
  {
    service:"Remembrance Ceremonies",
    desc:"There is a definite comfort in coming together with others to remember someone. I can help plan a personalised, creative event at a location that holds special meaning to you. The ceremony can, if you wish, reflect spiritual beliefs and can be tailored to suit the sensibilities of both the person who has passed and all their family and friends. Working together the possibilities for a meaningful remembrance event are within your reach."
  },  
  {
    service:"Baby Naming Ceremony",
    desc:"Instead of a traditional church christening, this naming Ceremony follows your aspirations in imparting a lifelong name to your precious offspring, it is one of the most important ceremonies of our lives and will remain as part of us for many years to come. We impart this gift to our young and it is a most important bestowal, let’s celebrate it in a way that you wish!"
  },  
  {
    service:"Renewal of Vows",
    desc:"Maybe you want more than just a 'party' for your significant anniversary! Why not allow me to work with you to perform a renewal of vows, with all your friends and family around you."
  },  
  {
    service:"Business Launch Ceremony and Business amalgamation cermony",
    desc:"These specialist events require more of an MC approach. These types of ceremonies are extremely important in the ongoing success of a new or amalgamated business venture, and I am experienced in working with entrepreneurial directors to make them special and successful for employees, suppliers, financers, and potential and ongoing Clients."
  }
];


function OtherTab() {
  return (
    <div className='tab-page'>
      <div id='other-title-card' className='card'>
        <div id='other-title-text'>
          <div className='quote'>
            <h2>"The miracle of life is this: the more we share the more we have"</h2>
            <p>Leonard Nemoy</p>
          </div>
        </div>
      </div>
      <div id='other-services-card'>
        <h1>Other Services</h1>
        <p>
          There are many other services that I, as a Celebrant, can offer.
          <br />
          These include:
        </p>
        {services.map(i=> <PointBox point={i.service} exp={i.desc} /> )}
      </div>
      <img id='buddy' src={DM}></img>
    </div>
  )
}

export default OtherTab